import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, reset, change, untouch, SubmissionError } from "redux-form";
import { Link, browserHistory } from "react-router";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import TextField from "../../components/Fields/TextField";
import MultiSelectField from "../../components/Fields/MultiSelectField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";

import {
  USERS_LIST_LOAD,
  USERS_LOAD,
  USERS_UNLOAD,
  USERS_USER_SELECT,
  USERS_CORRESPONDENTES_LOAD,
  USERS_ONLINE_LIST_LOAD,
  USERS_LOAD_UFS,
  USERS_UNLOAD_UFS,
  LOGOUT,
  CONFIRM_ACTION_OPEN,
  CONFIRM_GERENCIAR_AUTENTICACAO_OPEN,
} from "../../actions/Constants";
import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";
import MaskCPF from "../../components/Util/MaskCPF";
import SvgIcon from "react-icons-kit";
import { wrench } from "react-icons-kit/icomoon/wrench";
import { documentInverted } from "react-icons-kit/entypo/documentInverted";
import SelectField from "../../components/Fields/SelectField";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: "",
      usuarioExclusao: {},
      editando: false,
    };
  }

  componentWillMount() {
    this.props.onLoad(Promise.all([api.Roles.financialRoles(this.props.currentUser.idFinanceira)]));
    this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
    this.props.onUsersOnlineLoad(Promise.resolve(api.Financial.UsersOnline(this.props.currentUser.idFinanceira)));
    this.props.onLoadUf(api.Financial.ufsCadastradas(this.props.currentUser.idFinanceira));
    this.setState({ selectedFilter: this.props.currentUser.idFinanceira });
    Promise.resolve(api.Companies.authorized(this.props.currentUser.idFinanceira)).then((correspondentes) => {
      var compiledCorrespondentes = correspondentes.map((cor) => {
        return {
          label: cor.razaoSocial,
          value: cor.id,
        };
      });
      this.props.onCorrespondentesLoad([...compiledCorrespondentes]);
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
    this.props.onUnloadUf();
  }

  handleOnCancel = () => {
    this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
    this.clearForm();
  };

  clearForm = () => {
    this.setState({ editando: false });
    this.props.dispatch(change("UserListForm", "nome", null));
    this.props.dispatch(untouch("UserListForm", "nome"));
    this.props.dispatch(change("UserListForm", "cpf", null));
    this.props.dispatch(untouch("UserListForm", "cpf"));
    this.props.dispatch(change("UserListForm", "email", null));
    this.props.dispatch(untouch("UserListForm", "email"));
    this.props.dispatch(change("UserListForm", "roles", null));
    this.props.dispatch(untouch("UserListForm", "roles"));
    this.props.dispatch(change("UserListForm", "telefone", null));
    this.props.dispatch(untouch("UserListForm", "telefone"));
    this.props.dispatch(change("UserListForm", "cargo", null));
    this.props.dispatch(untouch("UserListForm", "cargo"));
    this.props.dispatch(change("UserListForm", "unidade", null));
    this.props.dispatch(untouch("UserListForm", "unidade"));
    this.props.dispatch(change("UserListForm", "ufs", null));
    this.props.dispatch(untouch("UserListForm", "ufs"));
  };

  handleOnSelectUserClick = (usr) => (ev) => {
    if (this.props.correspondentes.length > 1) {
      this.setState({ editando: true });
      this.props.dispatch(change("UserListForm", "idFinanceira", usr.idFinanceira));
    }
    this.props.onUserSelect(usr);
    this.props.dispatch(change("UserListForm", "nome", usr.nome));
    this.props.dispatch(change("UserListForm", "cpf", usr.cpf));
    this.props.dispatch(change("UserListForm", "email", usr.email));
    this.props.dispatch(change("UserListForm", "roles", usr.perfis));
    this.props.dispatch(change("UserListForm", "telefone", usr.telefone));
    this.props.dispatch(change("UserListForm", "cargo", usr.cargo));
    this.props.dispatch(change("UserListForm", "unidade", usr.unidade));
    this.props.dispatch(change("UserListForm", "ufs", usr.ufs));
    this.HandleScroll();
  };

  formSubmit(values) {
    var props = this.props;
    const errors = {};
    if (!values.roles || values.roles.length == 0) {
      errors.roles = "Campo obrigatório";
    }
    if (!values.ufs || values.ufs.length == 0) {
      errors.ufs = "Campo obrigatório";
    }
    if (errors && !_.isEmpty(errors)) {
      toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
      throw new SubmissionError(errors);
    }
    var user = {
      cpf: values.cpf.replace(/[^\d]/g, ""),
      email: values.email,
      telefone: values.telefone,
      nome: values.nome,
      cargo: values.cargo,
      unidade: values.unidade,
      perfis: [],
      ufs: [],
      idFinanceira: this.props.correspondentes.length > 1 && this.state.editando ? values.idFinanceira : this.state.selectedFilter,
    };

    _.forEach(values.roles, function (role) {
      user.perfis = [role.id, ...user.perfis];
    });

    _.forEach(values.ufs, function (uf) {
      user.ufs = [uf.uf, ...user.ufs];
    });

    if (this.props.user) {
      user.id = this.props.user.id;
      Promise.resolve(api.Users.update(user))
        .then((ret) => {
          toastr.success("Sucesso", "Usuário alterado");
          this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
          this.setState({ editando: false });
          this.props.reset();
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", resp.response.body.message);
          }
        });
    } else {
      Promise.resolve(api.Users.create(user))
        .then((ret) => {
          toastr.success("Sucesso", "Usuário cadastrado");
          this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
          this.setState({ editando: false });
          this.props.reset();
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", resp.response.body.message);
          }
        });
    }
  }

  handleOnBlockClick = (id) => (ev) => {
    Promise.resolve(api.Users.block(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário bloqueado");
      this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
    });
  };

  onActionUpdateStatus() {
    this.props.onActionOpen({
      description: `Confirma a exclusão do usuário?`,
      onSuccess: this.handleOnUpdateDelete.bind(this),
    });
  }

  handleOnUpdateDelete() {
    var props = this.props;
    Promise.resolve(api.Users.updateStatus(this.state.usuarioExclusao.id, 1))
      .then((resp) => {
        this.setState({ usuarioExclusao: {} });
        toastr.success("Sucesso", "Usuário excluído");
        this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", resp.response.body.message);
        }
      });
  }

  // onActionDeleteOpen() {
  //   this.props.onActionOpen({
  //     description: `Confirma a exclusão do usuário?`,
  //     onSuccess: this.handleOnDelete.bind(this),
  //   });
  // }

  onActionGerenAut() {
    Promise.resolve(api.Financial.get(this.state.selectedFilter ? this.state.selectedFilter : this.props.currentUser.idFinanceira)).then((financeira) => {
      this.props.onGerenciarAutenticacaoOpen({
        idFinanceira: financeira.id,
        gerenciarAutenticacao: parseInt(financeira.desabilitaSenhaOperacao),
      });
    });
  }

  // handleOnDelete() {
  //   var props = this.props;
  //   Promise.resolve(api.Users.deleteUser(this.state.usuarioExclusao.id))
  //     .then((resp) => {
  //       this.setState({ usuarioExclusao: {} });
  //       toastr.success("Sucesso", "Usuário excluído");
  //       this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
  //     })
  //     .catch(function (resp) {
  //       if (resp === "Unauthorized" || resp === "jwt expired") {
  //         api.Auth.logout();
  //         props.onLogout();
  //       } else {
  //         toastr.error("Erro", resp.response.body.message);
  //       }
  //     });
  // }

  handleOnUnblockClick = (id) => (ev) => {
    Promise.resolve(api.Users.unblock(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário desbloqueado");
      this.props.onListLoad(api.Users.companyUsers(this.props.currentUser.idFinanceira, true));
    });
  };

  filteredUsers = () => {
    if (this.state.selectedFilter) {
      return this.props.all.filter((user) => user.idFinanceira == this.state.selectedFilter);
    } else {
      return this.props.all;
    }
  };

  changeSelectedFilter = (e) => {
    this.setState({ selectedFilter: e.target.value });
    this.props.onLoad(Promise.all([api.Roles.financialRoles(e.target.value)]));
    this.props.onLoadUf(api.Financial.ufsCadastradas(e.target.value));
    this.clearForm();
  };

  changeSelectedDelegaPara = (e) => {
    this.props.onLoad(Promise.all([api.Roles.financialRoles(e.target.value)]));
    this.props.onLoadUf(api.Financial.ufsCadastradas(e.target.value));
    this.props.dispatch(change("UserListForm", "roles", null));
    this.props.dispatch(untouch("UserListForm", "roles"));
    this.props.dispatch(change("UserListForm", "ufs", null));
    this.props.dispatch(untouch("UserListForm", "ufs"));
  };

  HandleScroll() {
    window.scrollTo(0, 0);
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Usuários do sistema" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools"></div>
                  {this.props.correspondentes.length > 1 && (
                    <div>
                      Selecionar Financeira
                      <div className="row">
                        <div className="col-lg-4 m-t-sm">
                          <select className="form-control custom-select" onChange={(e) => this.changeSelectedFilter(e)}>
                            {this.financeirasOptions()}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="panel-heading">Usuário</div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    {this.props.correspondentes.length > 1 && this.state.editando && (
                      <div className="row">
                        <div className="col-md-4">
                          <Field
                            name="idFinanceira"
                            label="Financeira"
                            data={this.props.correspondentes}
                            textField="label"
                            valueField="value"
                            component={SelectField}
                            type="text"
                            onChange={(e) => this.changeSelectedDelegaPara(e)}
                          />
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-3">
                        <Field name="nome" label="Nome" component={TextField} type="text" validate={[FieldValidation.required]} />
                      </div>
                      <div className="col-md-2">
                        <Field name="cpf" label="CPF" component={TextField} type="text" normalize={FieldNormalize.CPF} validate={[FieldValidation.required, FieldValidation.cpf]} />
                      </div>
                      <div className="col-md-3">
                        <Field name="email" label="Email" component={TextField} type="text" validate={[FieldValidation.email, FieldValidation.required]} />
                      </div>
                      <div className="col-md-2">
                        <Field name="telefone" label="Telefone" normalize={FieldNormalize.PHONE} component={TextField} type="text" />
                      </div>
                      <div className="col-md-2">
                        <Field name="ufs" label="UFs habilitadas" data={this.props.ufs} component={MultiSelectField} type="text" textField="uf" valueField="uf" validate={[FieldValidation.required]} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="unidade" label="Unidade" component={TextField} type="text" />
                      </div>
                      <div className="col-md-3">
                        <Field name="cargo" label="Função" component={TextField} type="text" />
                      </div>
                      <div className="col-md-6">
                        <Field name="roles" label="Perfil" data={this.props.roles} component={MultiSelectField} type="text" textField="nome" valueField="sigla" validate={[FieldValidation.required]} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" to="/secure/users" onClick={this.handleOnCancel}>
                            {" "}
                            Cancelar
                          </Link>
                          {this.props.currentUser.funcionalidades.includes(15) && (
                            <button type="submit" className="btn btn-info" disabled={this.props.inProgress || this.props.submitting || this.props.pristine}>
                              {" "}
                              {this.props.user ? "Alterar" : "Adicionar"}
                            </button>
                          )}
                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools"></div>
                  Usuários cadastrados
                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-right">
                        <Link className="btn btn-info" onClick={() => this.onActionGerenAut()}>
                          <i className="fas fa-universal-access"></i> Gerenciar Autenticação
                        </Link>
                        {this.props.currentUser.funcionalidades.includes(37) && (
                          <Link className="btn btn-info" to={`/secure/userLogAudit/${this.state.selectedFilter}`}>
                            {" "}
                            <SvgIcon size={20} icon={documentInverted} />
                            Log Auditoria
                          </Link>
                        )}
                        {this.props.currentUser.funcionalidades.includes(40) && (
                          <Link className="btn btn-info" to={`/secure/financialProfile/${this.state.selectedFilter}`}>
                            {" "}
                            <SvgIcon size={20} icon={wrench} />
                            Gerenciar Perfis
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="text-center align-middle">Nome</th>
                          <th className="text-center align-middle">CPF</th>
                          <th className="text-center align-middle">Email</th>
                          <th className="text-center align-middle">Telefone</th>
                          <th className="text-center align-middle">Perfil</th>
                          <th className="text-center align-middle">Último Acesso</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{this.renderUsersList()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">Usuários Online</div>
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.usersOnline && this.props.usersOnline.length > 0 ? (
                      <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="text-center align-middle">Nome</th>
                            <th className="text-center align-middle">CPF</th>
                            <th className="text-center align-middle">IP</th>
                            <th className="text-center align-middle">Último Acesso</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderUsersOnline()}</tbody>
                      </table>
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  financeirasOptions() {
    if (!this.props.correspondentes) {
      return null;
    }
    return this.props.correspondentes.map((correspondente) => (
      <option key={correspondente.label} value={correspondente.value} selected={this.props.currentUser.idFinanceira == correspondente.value ? true : false}>
        {correspondente.label}
      </option>
    ));
  }

  renderUsersList() {
    if (!this.filteredUsers()) {
      return null;
    }
    return this.filteredUsers().map((user, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{user.nome}</td>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(user.cpf)}</td>
          <td className="text-center align-middle">{user.email}</td>
          <td className="text-center align-middle">{user.telefone}</td>
          <td className="text-center align-middle">{this.renderRoles(user.perfis)}</td>
          <td className="text-center align-middle">
            {user.ultimoAcesso ? moment(user.ultimoAcesso).format("DD/MM/YYYY HH:MM") : "-"} {user.ip ? "(" + user.ip + ")" : ""}
          </td>
          <td className="text-center align-middle text-center">
            <div className="btn-group">
              {/* {!user.ultimoAcesso ? (
                <Link className="btn btn-sm btn-default" onClick={() => this.onActionDeleteOpen(this.setState({ usuarioExclusao: { id: user.id, nome: user.nome } }))}>
                  <i className="fas fa-trash" title="Excluir"></i>
                </Link>
              ) : ( */}
              <Link className="btn btn-sm btn-default" onClick={() => this.onActionUpdateStatus(this.setState({ usuarioExclusao: { id: user.id, nome: user.nome } }))}>
                <i className="fas fa-trash" title="Excluir"></i>
              </Link>
              {/* )} */}
              {user.blocked === 0 && this.props.currentUser.funcionalidades.includes(17) && (
                <Link className="btn btn-sm btn-default" onClick={this.handleOnBlockClick(user.id)}>
                  <i className="fas fa-ban" title="Bloquear"></i>
                </Link>
              )}
              {user.blocked >= 1 && this.props.currentUser.funcionalidades.includes(17) && (
                <Link className="btn btn-sm btn-default" onClick={this.handleOnUnblockClick(user.id)}>
                  <i className="far fa-check-circle" title="Ativar"></i>
                </Link>
              )}
              {this.props.currentUser.funcionalidades.includes(16) && (
                <button className="btn btn-sm btn-default" onClick={this.handleOnSelectUserClick(user)}>
                  <i className="far fa-edit" title="Editar"></i>
                </button>
              )}
              <Link
                to={
                  _.findIndex(this.props.currentUser.perfis, function (perfil) {
                    return perfil === "ALIAS";
                  }) > -1
                    ? `/alias/userLogAcess/${user.id}`
                    : `/secure/userLogAcess/${user.id}`
                }
                className="btn btn-sm btn-default"
                title="Log de Acesso"
              >
                <i className="fas fa-laptop"></i>
              </Link>
            </div>
          </td>
        </tr>
      );
    });
  }

  renderUsersOnline() {
    if (!this.props.usersOnline) {
      return null;
    }
    var usersOnline = [];
    if (this.props.all && this.props.all.length > 0) {
      for (var user of this.props.all) {
        for (var userOnline of this.props.usersOnline) {
          if (user.cpf == userOnline.cpf) {
            var user = {
              id: userOnline.id,
              nome: userOnline.nome,
              cpf: userOnline.cpf,
              ip: user.ip,
              ultimoAcesso: user.ultimoAcesso,
            };
            usersOnline.push(user);
            break;
          }
        }
      }
    }
    return usersOnline.map((user, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{user.nome}</td>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(user.cpf)}</td>
          <td className="text-center align-middle">{user.ip}</td>
          <td className="text-center align-middle">{user.ultimoAcesso ? moment(user.ultimoAcesso).format("DD/MM/YYYY HH:MM") : "-"}</td>
        </tr>
      );
    });
  }

  renderRoles(perfis) {
    if (perfis) {
      return perfis.map((perfil, index) => {
        return (
          <div className="m-b-xs" key={index}>
            <span className="label m-r-xs">
              <small>{perfil.nome}</small>
            </span>
            <br />
          </div>
        );
      });
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: USERS_LOAD, payload }),
  onUnload: (payload) => dispatch({ type: USERS_UNLOAD, payload }),
  onListLoad: (payload) => dispatch({ type: USERS_LIST_LOAD, payload }),
  onUserSelect: (payload) => dispatch({ type: USERS_USER_SELECT, payload }),
  onCorrespondentesLoad: (payload) => dispatch({ type: USERS_CORRESPONDENTES_LOAD, payload }),
  onUsersOnlineLoad: (payload) => dispatch({ type: USERS_ONLINE_LIST_LOAD, payload }),
  onLoadUf: (payload) => dispatch({ type: USERS_LOAD_UFS, payload }),
  onUnloadUf: (payload) => dispatch({ type: USERS_UNLOAD_UFS, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
  onGerenciarAutenticacaoOpen: (payload) => dispatch({ type: CONFIRM_GERENCIAR_AUTENTICACAO_OPEN, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.users,
  currentUser: state.common.currentUser,
  initialValues: state.users.user ? { ...state.users.user, roles: state.users.user.perfis } : null,
});

const form = reduxForm({
  form: "UserListForm",
  enableReinitialize: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(UserList));
