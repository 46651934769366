import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, reset, change, untouch, SubmissionError } from "redux-form";
import { Link, browserHistory } from "react-router";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import TextField from "../../components/Fields/TextField";
import MultiSelectField from "../../components/Fields/MultiSelectField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import MaskCPF from "../../components/Util/MaskCPF";
import MaskDate from "../../components/Util/MaskDate";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import SvgIcon from "react-icons-kit";
import { wrench } from "react-icons-kit/icomoon/wrench";

import {
  USERS_LIST_LOAD,
  USERS_LOAD,
  USERS_USER_SELECT,
  USERS_ONLINE_LIST_LOAD,
  USERS_LOAD_UFS,
  USERS_UNLOAD_UFS,
  LOGOUT,
  CONFIRM_ACTION_OPEN,
  USERS_LIST_FINANCIAL_LOAD,
  FINANCIAL_LIST_LOAD,
} from "../../actions/Constants";
import api from "../../api/apiUtil";
import { documentInverted } from "react-icons-kit/entypo/documentInverted";
import DropdownListField from "../../components/Fields/DropdownListField";
import SelectField from "../../components/Fields/SelectField";

import Title from "../../components/Layout/Title";

class FinancialUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarioExclusao: {},
      financeiraParam: null,
      user: null,
    };
  }

  componentWillMount() {
    this.props.onLoad(Promise.all([api.Roles.financialRoles(this.props.params.slug)]));
    Promise.resolve(api.Users.companyUsers(this.props.params.slug)).then((all) => {
      this.props.onListLoad(all);
      if (this.props.params.usuario) {
        let user = all.find((item) => item.id == this.props.params.usuario);
        if (user) {
          this.handleOnSelectUserClick(user);
        }
      }
    });
    this.props.onUsersOnlineLoad(api.Financial.UsersOnline(this.props.params.slug));
    Promise.resolve(api.Financial.ufsCadastradas(this.props.params.slug)).then((res) => {
      this.props.onLoadUf(res);
      this.addAllUfs();
    });
    Promise.resolve(api.Companies.get(this.props.params.slug)).then((comp) => {
      this.setState({ financeiraParam: comp.razaoSocial });
      Promise.resolve(api.Companies.authorized(comp.delegaPara ? comp.delegaPara : this.props.params.slug)).then((all) => {
        //SE NAO TIVER DELEGAPARA PODE SER A 'MAE'
        this.props.onCorrespondentesLoad(all);
      });
    });
  }

  componentWillUnmount() {
    this.props.onUnloadUf();
  }

  addAllUfs() {
    this.props.dispatch(change("FinancialUserForm", "ufs", this.props.ufs));
  }

  formSubmit(values) {
    var props = this.props;
    const errors = {};
    if (!values.roles || values.roles.length == 0) {
      errors.roles = "Campo obrigatório";
    }
    if (!values.ufs || values.ufs.length == 0) {
      errors.ufs = "Campo obrigatório";
    }
    if (errors && !_.isEmpty(errors)) {
      toastr.error("Erro", "Campos obrigatórios não foram preenchidos.");
      throw new SubmissionError(errors);
    }
    var user = {
      cpf: values.cpf.replace(/[^\d]/g, ""),
      email: values.email,
      nome: values.nome,
      cargo: values.cargo,
      unidade: values.unidade,
      perfis: [],
      ufs: [],
      idFinanceira: this.props.financeiras.length > 1 && this.state.user ? values.financeira.id : this.props.params.slug,
      telefone: values.telefone,
      tipo: values.tipo !== "S" ? null : values.tipo,
      tipoAcesso: values.tipoAcesso == "Normal" ? null : values.tipoAcesso,
    };

    _.forEach(values.roles, function (role) {
      user.perfis = [role.id, ...user.perfis];
    });

    _.forEach(values.ufs, function (uf) {
      user.ufs = [uf.uf, ...user.ufs];
    });

    if (this.state.user) {
      user.id = this.state.user.id;
      Promise.resolve(api.Users.update(user))
        .then((ret) => {
          toastr.success("Sucesso", "Usuário alterado");
          this.props.onListLoad(api.Users.companyUsers(this.props.params.slug));
          this.setState({ user: null });
          this.props.reset();
          this.addAllUfs();
        })
        .catch(function (resp) {
          if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", resp.response.body.message);
          }
        });
    } else {
      Promise.resolve(api.Users.create(user))
        .then((ret) => {
          toastr.success("Sucesso", "Usuário cadastrado");
          this.props.onListLoad(api.Users.companyUsers(this.props.params.slug));
          this.setState({ user: null });
          this.props.reset();
          this.addAllUfs();
        })
        .catch(function (resp) {
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", resp.response.body.message);
          }
        });
    }
  }

  handleOnBlockClick = (id) => (ev) => {
    Promise.resolve(api.Users.block(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário bloqueado");
      this.props.onListLoad(api.Users.companyUsers(this.props.params.slug));
    });
  };

  onActionUpdateStatus() {
    this.props.onActionOpen({
      description: `Confirma a exclusão do usuário?`,
      onSuccess: this.handleOnUpdateDelete.bind(this),
    });
  }

  handleOnUpdateDelete() {
    var props = this.props;
    Promise.resolve(api.Users.updateStatus(this.state.usuarioExclusao.id, 1))
      .then((resp) => {
        this.setState({ usuarioExclusao: {} });
        toastr.success("Sucesso", "Usuário excluído");
        this.props.onListLoad(api.Users.companyUsers(this.props.params.slug));
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", resp.response.body.message);
        }
      });
  }

  // onActionDeleteOpen() {
  //   this.props.onActionOpen({
  //     description: `Confirma a exclusão do usuário?`,
  //     onSuccess: this.handleOnDelete.bind(this),
  //   });
  // }

  // handleOnDelete() {
  //   var props = this.props;
  //   Promise.resolve(api.Users.deleteUser(this.state.usuarioExclusao.id))
  //     .then((resp) => {
  //       this.setState({ usuarioExclusao: {} });
  //       toastr.success("Sucesso", "Usuário excluído");
  //       this.props.onListLoad(api.Users.companyUsers(this.props.params.slug));
  //     })
  //     .catch(function (resp) {
  //       if (resp === "Unauthorized" || resp === "jwt expired") {
  //         api.Auth.logout();
  //         props.onLogout();
  //       } else {
  //         toastr.error("Erro", resp.response.body.message);
  //       }
  //     });
  // }

  handleOnUnblockClick = (id) => (ev) => {
    Promise.resolve(api.Users.unblock(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário desbloqueado");
      this.props.onListLoad(api.Users.companyUsers(this.props.params.slug));
    });
  };

  handleOnSelectUserClick = (usr) => {
    //this.props.onUserSelect(usr);
    this.setState({ user: usr });
    this.props.dispatch(change("FinancialUserForm", "nome", usr.nome));
    this.props.dispatch(change("FinancialUserForm", "cpf", usr.cpf));
    this.props.dispatch(change("FinancialUserForm", "email", usr.email));
    this.props.dispatch(change("FinancialUserForm", "roles", usr.perfis));
    this.props.dispatch(change("FinancialUserForm", "telefone", usr.telefone));
    this.props.dispatch(change("FinancialUserForm", "cargo", usr.cargo));
    this.props.dispatch(change("FinancialUserForm", "unidade", usr.unidade));
    this.props.dispatch(change("FinancialUserForm", "ufs", usr.ufs));
    this.props.dispatch(change("FinancialUserForm", "financeira", { id: usr.idFinanceira }));
    this.props.dispatch(change("FinancialUserForm", "tipo", usr.tipo));
    this.props.dispatch(change("FinancialUserForm", "tipoAcesso", usr.tipoAcesso));
    this.HandleScroll();
  };

  handleOnCancel = () => (ev) => {
    ev.preventDefault();
    this.setState({ user: null });
    this.props.onListLoad(api.Users.companyUsers(this.props.params.slug));
    this.props.dispatch(change("FinancialUserForm", "nome", null));
    this.props.dispatch(untouch("FinancialUserForm", "nome"));
    this.props.dispatch(change("FinancialUserForm", "cpf", null));
    this.props.dispatch(untouch("FinancialUserForm", "cpf"));
    this.props.dispatch(change("FinancialUserForm", "email", null));
    this.props.dispatch(untouch("FinancialUserForm", "email"));
    this.props.dispatch(change("FinancialUserForm", "roles", null));
    this.props.dispatch(untouch("FinancialUserForm", "roles"));
    this.props.dispatch(change("FinancialUserForm", "telefone", null));
    this.props.dispatch(untouch("FinancialUserForm", "telefone"));
    this.props.dispatch(change("FinancialUserForm", "cargo", null));
    this.props.dispatch(untouch("FinancialUserForm", "cargo"));
    this.props.dispatch(change("FinancialUserForm", "unidade", null));
    this.props.dispatch(untouch("FinancialUserForm", "unidade"));
    this.props.dispatch(change("FinancialUserForm", "ufs", null));
    this.props.dispatch(untouch("FinancialUserForm", "ufs"));
    this.props.dispatch(change("FinancialUserForm", "financeira", null));
    this.props.dispatch(untouch("FinancialUserForm", "financeira"));
    this.props.dispatch(change("FinancialUserForm", "tipo", null));
    this.props.dispatch(untouch("FinancialUserForm", "tipo"));
    this.props.dispatch(change("FinancialUserForm", "tipoAcesso", null));
    this.props.dispatch(untouch("FinancialUserForm", "tipoAcesso"));
    this.addAllUfs();
  };

  renderPerfilListPDF(perfil) {
    if (!perfil || perfil.length === 0) {
      return "";
    }
    return perfil.map((p, index) => {
      return p.nome + "\n";
    });
  }

  exportPDF = () => (ev) => {
    ev.preventDefault();
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 70;

    var title = `Usuários Cadastrados`;

    const headers = [["Nome", "CPF", "Email", "Telefone", "Função", "Perfil", "Último Acesso"]];

    const data = this.props.all.map((user) => [
      user.nome,
      MaskCPF.TO_CPF(user.cpf),
      user.email,
      user.telefone,
      user.cargo,
      // user.unidade,
      this.renderPerfilListPDF(user.perfis),
      MaskDate.TO_BR_DATETIME(user.ultimoAcesso) + user.ip,
    ]);

    console.log(this.props.data);

    var totais = `Quantidade total de Usuários: ${data.length}`;

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        // 0: { columnWidth: 'wrap' },
        // 1: {
        //     columnWidth: 'wrap'
        // },
        // 2: {
        //     columnWidth: 'wrap'
        // },
        // 3: {
        //     columnWidth: 'wrap'
        // },
        // 4: {
        //   columnWidth: 'wrap'
        // },
        // 5: {
        //     columnWidth: 'wrap'
        // },
        // 6: {
        //   columnWidth: 'wrap'
        // }
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.text(totais, marginLeft, doc.autoTable.previous.finalY + 20);
    doc.save("RelatorioUsuários.pdf");
  };

  HandleScroll() {
    window.scrollTo(0, 0);
  }

  render() {
    const { handleSubmit, reset } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Usuários do sistema" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools">
                    {this.props.currentUser.funcionalidades.includes(32) && (
                      <Link to={`/alias/financialEdit/${this.props.params.slug}`} className="btn btn-xs btn-info" title="Financeira">
                        <i className="far fa-edit" />
                        Acessar Financeira
                      </Link>
                    )}
                  </div>
                  Financeira: {this.state.financeiraParam ? this.state.financeiraParam.toUpperCase() : ""}
                </div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="nome" label="Nome" component={TextField} type="text" validate={[FieldValidation.required]} />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="cpf"
                          label="CPF"
                          component={TextField}
                          type="text"
                          normalize={FieldNormalize.CPF}
                          validate={[FieldValidation.required, FieldValidation.cpf]}
                          disabled={this.state.user}
                        />
                      </div>
                      <div className="col-md-3">
                        <Field name="email" label="Email" component={TextField} type="text" validate={[FieldValidation.email, FieldValidation.required]} />
                      </div>
                      <div className="col-md-2">
                        <Field name="telefone" label="Telefone" normalize={FieldNormalize.PHONE} component={TextField} type="text" />
                      </div>
                      <div className="col-md-2">
                        <Field name="ufs" label="UF" data={this.props.ufs} component={MultiSelectField} type="text" textField="uf" valueField="uf" validate={[FieldValidation.required]} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="unidade" label="Unidade" component={TextField} type="text" />
                      </div>
                      <div className={this.props.financeiras.length > 1 && this.state.user ? "col-md-2" : "col-md-4"}>
                        <Field name="cargo" label="Função" component={TextField} type="text" />
                      </div>
                      {this.props.financeiras && this.props.financeiras.length > 1 && this.state.user && (
                        <div className="col-md-4">
                          <Field
                            name="financeira"
                            label="Financeira"
                            data={this.props.financeiras}
                            credor={true}
                            textField="razaoSocial"
                            valueField="id"
                            component={DropdownListField}
                            type="text"
                            validate={[FieldValidation.required]}
                          />
                        </div>
                      )}
                      <div className={this.props.financeiras.length > 1 && this.state.user ? "col-md-3" : "col-md-5"}>
                        <Field name="roles" label="Perfil" data={this.props.roles} component={MultiSelectField} type="text" textField="nome" valueField="sigla" validate={[FieldValidation.required]} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Field
                          name="tipo"
                          label="Suporte"
                          data={[
                            { id: null, name: "Não" },
                            { id: "S", name: "Sim" },
                          ]}
                          valueField="id"
                          textField="name"
                          component={SelectField}
                          type="text"
                        />
                      </div>
                      <div className="col-md-2">
                        <Field
                          name="tipoAcesso"
                          label="Tipo acesso"
                          data={[
                            { id: null, name: "Financeira" },
                            { id: "API", name: "API" },
                            { id: "SEND", name: "SEND" },
                          ]}
                          valueField="id"
                          textField="name"
                          component={SelectField}
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" onClick={this.handleOnCancel()}>
                            {" "}
                            Cancelar
                          </Link>
                          {this.state.user && (
                            <button type="submit" className="btn btn-info" disabled={this.props.inProgress || this.props.submitting || this.props.pristine}>
                              {" "}
                              {"Alterar"}
                            </button>
                          )}
                          {this.props.currentUser.funcionalidades.includes(85) && !this.state.user && (
                            <button type="submit" className="btn btn-info" disabled={this.props.inProgress || this.props.submitting || this.props.pristine}>
                              {" "}
                              {"Adicionar"}
                            </button>
                          )}

                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">
                  <div className="panel-tools"></div>
                  Usuários cadastrados
                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-right">
                        {this.props.currentUser.funcionalidades.includes(84) && (
                          <Link className="btn btn-info" to={`/alias/userLogAudit/${this.props.params.slug}`}>
                            {" "}
                            <SvgIcon size={20} icon={documentInverted} />
                            Log Auditoria
                          </Link>
                        )}
                        {this.props.currentUser.funcionalidades.includes(83) && (
                          <Link className="btn btn-info" to={`/alias/financialProfile/${this.props.params.slug}`}>
                            {" "}
                            <SvgIcon size={20} icon={wrench} />
                            Gerenciar Perfis
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel-body">
                  {this.props.all.length > 0 ? (
                    <Link className="btn btn-default float-right" onClick={this.exportPDF()} title="Gerar PDF">
                      {" "}
                      <SvgIcon size={20} icon={filePdf} />
                    </Link>
                  ) : null}

                  <div className="table-responsive">
                    <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="text-center align-middle">Nome</th>
                          <th className="text-center align-middle">CPF</th>
                          <th className="text-center align-middle">Email</th>
                          <th className="text-center align-middle">Telefone</th>
                          {/*<th className="text-center align-middle">Função</th>
                          <th className="text-center align-middle">Unidade</th>*/}
                          <th className="text-center align-middle">Perfil</th>
                          <th className="text-center align-middle">Último Acesso</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{this.renderUsersList()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="hpanel">
                <div className="panel-heading">Usuários Online</div>
                <div className="panel-body">
                  <div className="table-responsive">
                    {this.props.usersOnline && this.props.usersOnline.length > 0 ? (
                      <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="text-center align-middle">Nome</th>
                            <th className="text-center align-middle">CPF</th>
                            <th className="text-center align-middle">IP</th>
                            <th className="text-center align-middle">Último Acesso</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderUsersOnline()}</tbody>
                      </table>
                    ) : (
                      <small>Nenhum registro encontrado</small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUsersList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((user, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{user.nome}</td>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(user.cpf)}</td>
          <td className="text-center align-middle">{user.email}</td>
          <td className="text-center align-middle">{user.telefone}</td>
          {/*<td className="text-center align-middle">{user.cargo}</td>
            <td className="text-center align-middle">{user.unidade}</td>*/}
          <td className="text-center align-middle">{this.renderRoles(user.perfis)}</td>
          <td className="text-center align-middle">
            {user.ultimoAcesso ? moment(user.ultimoAcesso).format("DD/MM/YYYY HH:MM") : "-"} {user.ip ? "(" + user.ip + ")" : ""}
          </td>
          <td className="text-center align-middle text-center">
            <div className="btn-group">
              {this.props.currentUser.funcionalidades.includes(80) && (
                <>
                  {/* {!user.ultimoAcesso ? (
                    <Link className="btn btn-sm btn-default" onClick={() => this.onActionDeleteOpen(this.setState({ usuarioExclusao: { id: user.id, nome: user.nome } }))}>
                      <i className="fas fa-trash" title="Excluir"></i>
                    </Link>
                  ) : ( */}
                  <Link className="btn btn-sm btn-default" onClick={() => this.onActionUpdateStatus(this.setState({ usuarioExclusao: { id: user.id, nome: user.nome } }))}>
                    <i className="fas fa-trash" title="Excluir"></i>
                  </Link>
                  {/* )} */}
                </>
              )}
              {this.props.currentUser.funcionalidades.includes(81) && (
                <>
                  {user.blocked === 0 && (
                    <Link className="btn btn-sm btn-default" onClick={this.handleOnBlockClick(user.id)} title="Bloquear">
                      <i className="fas fa-ban"></i>
                    </Link>
                  )}
                  {user.blocked >= 1 && (
                    <Link className="btn btn-sm btn-default" onClick={this.handleOnUnblockClick(user.id)} title="Ativar">
                      <i className="far fa-check-circle"></i>
                    </Link>
                  )}
                </>
              )}
              {this.props.currentUser.funcionalidades.includes(82) && (
                <button className="btn btn-sm btn-default" onClick={() => this.handleOnSelectUserClick(user)} title="Editar">
                  <i className="far fa-edit"></i>
                </button>
              )}
              {this.props.currentUser.funcionalidades.includes(84) && (
                <Link to={`/secure/userLogAcess/${user.id}`} className="btn btn-sm btn-default" title="Log de Acesso">
                  <i className="fas fa-laptop"></i>
                </Link>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  renderUsersOnline() {
    if (!this.props.usersOnline) {
      return null;
    }

    var usersOnline = [];
    if (this.props.all && this.props.all.length > 0) {
      for (var user of this.props.all) {
        for (var userOnline of this.props.usersOnline) {
          if (user.cpf == userOnline.cpf) {
            var user = {
              id: userOnline.id,
              nome: userOnline.nome,
              cpf: userOnline.cpf,
              ip: user.ip,
              ultimoAcesso: user.ultimoAcesso,
            };
            usersOnline.push(user);
            break;
          }
        }
      }
    }
    return usersOnline.map((user, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{user.nome}</td>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(user.cpf)}</td>
          <td className="text-center align-middle">{user.ip}</td>
          <td className="text-center align-middle">{user.ultimoAcesso ? moment(user.ultimoAcesso).format("DD/MM/YYYY HH:MM") : "-"}</td>
        </tr>
      );
    });
  }

  renderRoles(perfis) {
    if (perfis) {
      return perfis.map((perfil, index) => {
        return (
          <div className="m-b-xs" key={index}>
            <span className="label m-r-xs">
              <small>{perfil.nome}</small>
            </span>
            <br />
          </div>
        );
      });
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: USERS_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: USERS_LIST_LOAD, payload }),
  onUserSelect: (payload) => dispatch({ type: USERS_USER_SELECT, payload }),
  onUsersOnlineLoad: (payload) => dispatch({ type: USERS_ONLINE_LIST_LOAD, payload }),
  onLoadUf: (payload) => dispatch({ type: USERS_LOAD_UFS, payload }),
  onUnloadUf: (payload) => dispatch({ type: USERS_UNLOAD_UFS, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
  onCorrespondentesLoad: (payload) => dispatch({ type: USERS_LIST_FINANCIAL_LOAD, payload }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const mapStateToProps = (state) => ({
  ...state.users,
  currentUser: state.common.currentUser,
  initialValues: state.users.user ? { ...state.users.user, roles: state.users.user.perfis } : null,
});

const form = reduxForm({
  form: "FinancialUserForm",
  enableReinitialize: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FinancialUser));
