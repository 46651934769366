import crypto from "crypto-browserify";

const algorithm = "aes-256-ctr";
const convertKey = (chave) => {
  return crypto.createHash("sha256").update(String(chave)).digest("base64").substr(0, 32);
};

const encrypt = (buffer, chave) => {
  // Create an initialization vector
  const iv = crypto.randomBytes(16);
  // Create a new cipher using the algorithm, key, and iv
  const cipher = crypto.createCipheriv(algorithm, convertKey(chave), iv);
  // Create the new (encrypted) buffer
  const result = Buffer.concat([iv, cipher.update(buffer), cipher.final()]);
  return result;
};

const decrypt = (encrypted, chave) => {
  // Get the iv: the first 16 bytes
  const iv = encrypted.slice(0, 16);
  // Get the rest
  encrypted = encrypted.slice(16);
  // Create a decipher
  const decipher = crypto.createDecipheriv(algorithm, convertKey(chave), iv);
  // Actually decrypt it
  const result = Buffer.concat([decipher.update(encrypted), decipher.final()]);
  return result;
};

const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

const months = [
  { label: "Janeiro", value: "0" },
  { label: "Fevereiro", value: "1" },
  { label: "Março", value: "2" },
  { label: "Abril", value: "3" },
  { label: "Maio", value: "4" },
  { label: "Junho", value: "5" },
  { label: "Julho", value: "6" },
  { label: "Agosto", value: "7" },
  { label: "Setembro", value: "8" },
  { label: "Outubro", value: "9" },
  { label: "Novembro", value: "10" },
  { label: "Dezembro", value: "11" },
];

const years = [
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
];

const monthsWithOptSel = [
  { label: "Selecione", value: "" },
  { label: "Janeiro", value: "01" },
  { label: "Fevereiro", value: "02" },
  { label: "Março", value: "03" },
  { label: "Abril", value: "04" },
  { label: "Maio", value: "05" },
  { label: "Junho", value: "06" },
  { label: "Julho", value: "07" },
  { label: "Agosto", value: "08" },
  { label: "Setembro", value: "09" },
  { label: "Outubro", value: "10" },
  { label: "Novembro", value: "11" },
  { label: "Dezembro", value: "12" },
];

const yearsWithOptSel = [
  { label: "Selecione", value: "" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
];

function getPreviousMonth() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const monthIndex = date.getMonth();
  return monthsWithOptSel[monthIndex + 1].value;
}

export default {
  encrypt,
  decrypt,
  arrayBufferToBase64,
  months,
  years,
  monthsWithOptSel,
  yearsWithOptSel,
  getPreviousMonth,
};
