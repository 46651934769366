import React from "react";
var owasp = require("owasp-password-strength-test");

owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 8,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4,
});

const required = (value) => (value ? undefined : "Campo obrigatório");
const requiredNot0 = (value) => (value && value != "0,00" ? undefined : "Campo obrigatório");
const maxLength = (max) => (value) => value && value.length > max ? `Deve ter ${max} caracteres ou menos` : undefined;
const maxLength2 = maxLength(2);
const maxLength3 = maxLength(3);
const maxLength4 = maxLength(4);
const maxLength5 = maxLength(5);
const maxLength6 = maxLength(6);
const maxLength8 = maxLength(8);
const maxLength10 = maxLength(10);
const maxLength11 = maxLength(11);
const maxLength20 = maxLength(20);
const maxLength21 = maxLength(21);
const maxLength30 = maxLength(30);
const maxLength40 = maxLength(40);
const maxLength50 = maxLength(50);
const maxLength180 = maxLength(180);

const minLength = (max) => (value) => value && value.length < max ? `Deve ter ${max} caracteres ou mais` : undefined;
const minLength6 = minLength(6);

const length = (l) => (value) => value && value.length !== l ? `Deve ter ${l} caracteres` : undefined;
const length4 = length(4);
const length17 = length(17);

const email = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? "Endereço de email inválido" : undefined);

const cpf = (value) => {
  if (value) {
    var soma = 0;
    var resto;
    var inputCPF = value.replace(/[^\d]/g, "");

    if (inputCPF == "00000000000") return "CPF inválido";
    var i = 0;
    for (i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) return "CPF inválido";

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) return "CPF inválido";
    return undefined;
  }
};

const cnpj = (value) => {
  var cnpj = value.replace(/[^\d]+/g, "");

  if (cnpj == "") return "CNPJ inválido";

  if (cnpj.length != 14) return "CNPJ inválido";

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return "CNPJ inválido";

  // Valida DVs
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return "CNPJ inválido";

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return "CNPJ inválido";

  return undefined;
};

const cpf_cnpj = (value) => {
  if (value && value.length < 14) {
    return "Valor Inválido";
  }
  if (value && value.length === 14) {
    return cpf(value);
  } else if (value && value.length > 14) {
    return cnpj(value);
  } else {
    return undefined;
  }
};

const cep = (value) => {
  var regex = "";
  var matches = /[0-9]{5}-[0-9]{3}/.exec(value);
  if (value && matches == null) {
    return "CEP Inválido";
  } else {
    return undefined;
  }
};

const number = (value) => (value && isNaN(Number(value)) ? "Deve ser um valor numérico" : undefined);

const date = (value) => {
  if (!value) return undefined;
  var matches = /^(\d{2})[-\/](\d{2})[-\/](\d{4})$/.exec(value);
  var matches2 = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/i.test(value);

  if (matches == null || !matches2) return "Data Inválida";

  var d = matches[1];
  var m = matches[2] - 1;
  var y = matches[3];
  var composedDate = new Date(y, m, d);

  if (composedDate.getDate() == d && composedDate.getMonth() == m && composedDate.getFullYear() == y) {
    return undefined;
  } else {
    return "Data Inválida";
  }
};

const chassi = (value) => {
  if (value && value.length > 17) {
    return "Chassi Inválido";
  } else {
    return undefined;
  }
};

const nospace = (value) => (value && !/^[^\s]+(\s+[^\s]+)*$/g.test(value) ? "Dados inválidos - remover espaços" : undefined);

const letterOrNumber = (value) => (value && !/[a-zA-Z]/.test(value[0]) && !/^\d+$/.test(value[0]) ? "Dados inválidos - primeiro caracter inválido" : undefined);

const noCharacterSpecial = (value) => (value && value.includes("+") ? "Dados inválidos - remover +" : undefined);

const vlMaximoTotal = (value) => {
  if (value) {
    if (parseFloat(value.replace(",", ".")) < 10000000) {
      return undefined;
    } else {
      return "Valor inválido";
    }
  }
};

const passwordValid = (value) => {
  if (value) {
    var result = owasp.test(value);
    if (result.errors && result.errors.length > 0) {
      for (let i = 0; i < result.errors.length; i++) {
        if (result.errors[i] == "The password must contain at least one special character.") {
          result.errors[i] = " A senha deve conter pelo menos um caractere especial.";
        } else if (result.errors[i] == "The password must contain at least one number.") {
          result.errors[i] = " A senha deve conter pelo menos um número.";
        } else if (result.errors[i] == "The password must contain at least one uppercase letter.") {
          result.errors[i] = " A senha deve conter pelo menos uma letra maiúscula.";
        } else if (result.errors[i] == "The password must contain at least one lowercase letter.") {
          result.errors[i] = " A senha deve conter pelo menos uma letra minúscula.";
        } else if (result.errors[i] == "The password may not contain sequences of three or more repeated characters.") {
          result.errors[i] = " A senha não pode conter sequências de três ou mais caracteres repetidos.";
        } else if (result.errors[i].includes("The password must be fewer than")) {
          result.errors[i] = result.errors[i].replace("The password must be fewer than", " A senha deve ter menos que");
          result.errors[i] = result.errors[i].replace("characters", "caracteres");
        } else if (result.errors[i].includes("The password must be at least")) {
          result.errors[i] = result.errors[i].replace("The password must be at least", " A senha deve ter pelo menos");
          result.errors[i] = result.errors[i].replace("characters long", "caracteres");
        }
      }
      return result.errors;
    } else {
      return undefined;
    }
  }
};

const HHMM = (value) => {
  if (value) {
    if (value.length < 5 || value.slice(0, 2) > 23 || value.slice(3, 5) > 59) {
      return "Horário Inválido";
    } else {
      return undefined;
    }
  }
};

const PLATE = (value) => {
  if (value) {
    if (value.length < 8) {
      return "Placa Inválida";
    } else {
      return undefined;
    }
  }
};

const gravame = (value) => {
  if (value) {
    if (value.length < 6 || value.length > 8 || /^0+$/.test(value)) {
      return "Gravame Inválido";
    } else {
      return undefined;
    }
  }
};

const dateDayMonth = (value) => {
  if (!value) return undefined;

  const matches = /^(\d{2})[-\/](\d{2})$/.exec(value);
  if (!matches) return "Data Inválida";

  const day = parseInt(matches[1]);
  const month = parseInt(matches[2]);

  if (month < 1 || month > 12) return "Mês Inválido";

  const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (day < 1 || day > daysInMonth[month - 1]) return "Dia Inválido";

  return undefined;
};

const ipRange = (value) => {
  if (!value) return undefined;

  const ipRanges = value.split(" ");

  for (let range of ipRanges) {
    if (!range) continue;

    const [ip, mask] = range.split("/");

    if (!mask || mask < 0 || mask > 32) {
      return "Máscara de rede inválida";
    }

    const octets = ip.split(".");

    if (octets.length !== 4) {
      return "Endereço IP inválido";
    }

    for (let octet of octets) {
      const num = parseInt(octet);
      if (isNaN(num) || num < 0 || num > 255) {
        return "Endereço IP inválido";
      }
    }
  }

  return undefined;
};

export default {
  required,
  requiredNot0,
  maxLength,
  maxLength2,
  maxLength8,
  maxLength21,
  maxLength11,
  maxLength4,
  maxLength20,
  maxLength3,
  maxLength40,
  maxLength6,
  maxLength10,
  maxLength30,
  maxLength5,
  maxLength50,
  maxLength180,
  minLength6,
  minLength,
  length4,
  length17,
  number,
  email,
  cep,
  cpf,
  cnpj,
  cpf_cnpj,
  date,
  nospace,
  vlMaximoTotal,
  chassi,
  passwordValid,
  HHMM,
  PLATE,
  letterOrNumber,
  gravame,
  noCharacterSpecial,
  dateDayMonth,
  ipRange,
};
