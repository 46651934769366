import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field, change, untouch, formValueSelector } from "redux-form";
import { Link } from "react-router";
import Icon from "react-icons-kit";
import { upload } from "react-icons-kit/typicons/upload";
import api from "../../api/apiUtil";
import MaskDecimal from "../../components/Util/MaskDecimal";
import { HOME_IMAGES_STATUS_LOAD } from "../../actions/Constants";
import TextDatePickerField from "../../components/Fields/TextDatePickerField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import MaskDate from "../../components/Util/MaskDate";
import { BeatLoader } from "react-spinners";
import { toastr } from "react-redux-toastr";

class ImagesStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterButtonsClickable: true,
      uf: null,
      executado: false,
      pesquisando: false,
      financeira: "0",
    };
  }

  componentWillMount() {
    //Faz a busca para o banco como padrão os últimos 30 dias
    this.handleSetDate("MONTH");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.uf) {
      if (this.state.uf !== nextProps.uf) {
        this.setState({ uf: nextProps.uf });
        this.setState({ executado: false });
      } else if (!this.state.executado) {
        this.handleSetDate("MONTH");
        this.setState({ executado: true });
      }
    }
    if (nextProps.financeira) {
      if (this.state.financeira !== nextProps.financeira) {
        this.setState({ financeira: nextProps.financeira });
        this.setState({ executado: false });
      } else if (!this.state.executado) {
        this.handleSetDate("MONTH");
        this.setState({ executado: true });
      }
    }
  }

  //Efetua a busca para o banco
  filter(values = {}) {
    this.setState({ pesquisando: true, filterButtonsClickable: false });
    let btnFiltrar = this.refs.btnFiltrar;
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });

    try {
      // Promise.resolve(api.Companies.authorized(this.props.currentUser.idFinanceira)).then((companies) => {
      // var promisesQtdEnvioImagensPendentes = companies.map(
      //   function (obj) {
      //     return api.Contracts.getQtdEnvioImagensPendentes(
      //       obj.cnpj,
      //       this.state.uf == "Todas" ? undefined : this.state.uf,
      //       values.dtInicial ? MaskDate.TO_DATABASE_DATE(values.dtInicial) : null,
      //       values.dtFinal ? MaskDate.TO_DATABASE_DATE(values.dtFinal) : null
      //     );
      //   }.bind(this)
      // );

      var promisesQtdEnvioImagensPendentes = [
        api.Contracts.getQtdEnvioImagensPendentes(
          this.state.financeira && this.state.financeira != 0 ? this.props.companies.filter((f) => `${f.id}` === this.state.financeira)[0].cnpj : "0",
          this.state.uf == "Todas" ? undefined : this.state.uf,
          values.dtInicial ? MaskDate.TO_DATABASE_DATE(values.dtInicial) : null,
          values.dtFinal ? MaskDate.TO_DATABASE_DATE(values.dtFinal) : null
        ),
      ];

      Promise.all(promisesQtdEnvioImagensPendentes).then((results) => {
        let contractsRegistredWithoutImage = 0;
        let contractsRegistred = 0;
        results.forEach(function (value) {
          contractsRegistredWithoutImage += value.filter((element) => element.status == "registradoSemImagem")[0].qtd;
          contractsRegistred += value.filter((element) => element.status == "registrado")[0].qtd;
        });
        let total = contractsRegistred + contractsRegistredWithoutImage;
        let percent = 0;
        if (contractsRegistredWithoutImage) {
          percent = (contractsRegistredWithoutImage * 100) / total;
        }

        this.props.onLoad([total, MaskDecimal.TO_BR(percent), contractsRegistredWithoutImage]);
        this.setState({ filterButtonsClickable: true, pesquisando: false });
        toastr.removeByType("info");
        if (btnFiltrar && (!total || total == 0)) {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
        }
      });
      // });
    } catch (error) {
      this.setState({ filterButtonsClickable: true });
    }
  }

  //Handle do botão FILTRAR
  handleOnFilter(values) {
    this.filter(values);
  }

  handleSetDate(type) {
    //Pega data atual e a data há 30 dias atrás.
    const today = new Date();
    const monthBefore = new Date();
    monthBefore.setDate(today.getDate() - 30);

    //Gera os valores de forma dinâmica, caso 'MONTH' utiliza o padrão de: Data Inicial = hoje, Data Final = 30 dias atrás, caso contrário, ambos são nulos.
    //DETALHE: Utiliza-se +1 no getMonth() pois o mês é um caso especial nas datas no javascript, sendo retornado o index dele e não o número do mês, o que implica num +1.

    const values = {
      dtInicial: null,
      dtFinal: null,
    };

    if (type === "MONTH") {
      let todaysDay = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
      let todaysMonth = today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1;
      let pastDay = monthBefore.getDate() < 10 ? "0" + monthBefore.getDate() : monthBefore.getDate();
      let pastMonth = monthBefore.getMonth() + 1 < 10 ? "0" + (monthBefore.getMonth() + 1) : monthBefore.getMonth() + 1;

      values.dtInicial = `${pastDay}/${pastMonth}/${monthBefore.getFullYear()}`;
      values.dtFinal = `${todaysDay}/${todaysMonth}/${today.getFullYear()}`;
    }

    //Muda os valores no state para os campos dtInicial/dtFinal e reseta o toque sobre estes campos.
    this.props.dispatch(change("ImagesStatusForm", "dtInicial", values.dtInicial));
    this.props.dispatch(untouch("ImagesStatusForm", "dtInicial"));
    this.props.dispatch(change("ImagesStatusForm", "dtFinal", values.dtFinal));
    this.props.dispatch(untouch("ImagesStatusForm", "dtFinal"));

    //Busca logo depois de mudar os valores nos campos entre geral/mês atual
    this.filter(values);
    return values;
  }

  render() {
    const { handleSubmit } = this.props;
    if (this.props.companies && this.props.companies.length > 0) {
      return (
        <div className="hpanel stats">
          <div className="panel-body h-100">
            <div className="stats-title float-left">
              {" "}
              <h3>Envio de imagem pendente</h3>{" "}
            </div>
            <div className="stats-icon float-right">
              <Icon icon={upload} size={40} />
            </div>
            <div className="m-t-xl">
              <span className="font-bold no-margins">Imagens a enviar</span>
              <div className="progress m-t-xs full progress-small">
                <div
                  style={{ width: this.props.imagesPercent ? Math.trunc(parseFloat(this.props.imagesPercent)) + "%" : this.props.imagesPercent + "%" }}
                  aria-valuemax="100"
                  aria-valuemin="0"
                  aria-valuenow="55"
                  role="progressbar"
                  className=" progress-bar progress-bar-warning"
                >
                  <span className="sr-only"> </span>
                </div>
              </div>
              <div className="row mx-auto text-center justify-content-around">
                <div className="col-xs-12">
                  <small className="stats-label">Envio de registro</small>
                  <h4>{this.props.contractsQuantity}</h4>
                </div>
                <div className="col-xs-12">
                  <small className="stats-label">Envio de registro sem imagem</small>
                  <h4>{this.props.imagesQuantity}</h4>
                </div>
                <div className="col-xs-12">
                  <small className="stats-label">% Sem Imagem</small>
                  <h4>{this.props.imagesPercent}%</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-semifooter">
            <form>
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="dtInicial"
                    label="Data Inicial"
                    validate={[FieldValidation.date]}
                    placeholder="00/00/0000"
                    data={this.props.dtInicial}
                    component={TextDatePickerField}
                    normalize={FieldNormalize.DATE}
                    type="text"
                    inline={false}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="dtFinal"
                    label="Data Final"
                    validate={[FieldValidation.date]}
                    placeholder="00/00/0000"
                    data={this.props.dtFinal}
                    component={TextDatePickerField}
                    normalize={FieldNormalize.DATE}
                    type="text"
                    inline={false}
                  />
                </div>
              </div>
              <div className="row justify-content-between m-b-sm">
                <div className="col-lg-6">
                  <button
                    disabled={!this.state.filterButtonsClickable}
                    className="btn btn-sm btn-default btn-block"
                    type="button"
                    onClick={() => {
                      this.handleSetDate("CANCEL");
                    }}
                  >
                    Geral
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    disabled={!this.state.filterButtonsClickable}
                    className="btn btn-sm btn-default btn-block"
                    type="button"
                    onClick={() => {
                      this.handleSetDate("MONTH");
                    }}
                  >
                    30 Dias
                  </button>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-lg-12">
                  <button
                    disabled={!this.state.filterButtonsClickable || this.state.pesquisando}
                    className="btn btn-sm btn-info btn-block"
                    type="submit"
                    ref="btnFiltrar"
                    onClick={handleSubmit(this.handleOnFilter.bind(this))}
                  >
                    {this.state.pesquisando ? "Aguarde..." : "Filtrar"}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="panel-footer">
            {this.props.funcionalidades.includes(5) && (
              <Link to="/secure/contracts/NI" className="btn btn-sm btn-success btn-block">
                Enviar Imagens
              </Link>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: HOME_IMAGES_STATUS_LOAD, payload }),
});

const mapStateToProps = (state) => ({
  ...state.home,
  dtInicial: selector(state, "dtInicial"),
  dtFinal: selector(state, "dtFinal"),
  currentUser: state.common.currentUser,
});

ImagesStatus.propTypes = {
  companies: PropTypes.array.isRequired,
  funcionalidades: PropTypes.array.isRequired,
};

const selector = formValueSelector("ImagesStatusForm");
const form = reduxForm({
  form: "ImagesStatusForm",
  destroyOnUnmount: true,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ImagesStatus));
